<template>
    <div class="page">
        <div class="block">
            <el-form ref="form" :model="form" label-width="0">
                <div class="title">忘记密码</div>
                <div class="inputMsg">手机号/邮箱</div>
                <el-row type="flex" align="center" style="margin-top:8px">
                    <el-input class="input" v-model="input" placeholder="请输入手机号/邮箱"></el-input>
                </el-row>
                <div class="inputMsg">验证码</div>
                <el-row type="flex" align="center" style="margin-top:8px">
                    <el-input class="input" v-model="form.captcha" placeholder="请输入验证码"></el-input>
                    <el-button type="success" :loading="sendCodeLoading" :disabled="sendCodeTime===0?false:true" @click="sendCode" style="height:100%;background-color:#1ecda4;border-color:#1ecda4">{{sendCodeBtnTxt}}</el-button>
                </el-row>
                <div class="inputMsg">新密码</div>
                <el-row type="flex" align="center" style="margin-top:8px">
                    <el-input class="input" v-model="form.newpassword" show-password placeholder="请输入新密码(8-30位的英文字母和数字)"></el-input>
                </el-row>
            </el-form>

            <div>
                <el-button type="text" class="step" round @click="step">下一步</el-button>
            </div>
            <div>
                <el-button @click="back" type="text" class="back" round>返回</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    let codeTimer
    import axiosZX from '@/axios/indexZX'
    export default {
        name: "forgetPassword",
        components: {},
        data() {
            return {
                input: "",
                form: {
                    mobile: "",
                    email: "",
                    type: "", //mobile|email
                    newpassword: "",
                    captcha: "",
                },
                sendCodeLoading: false,
                sendCodeTime: 0,
                sendCodeBtnTxt: "发送验证码",
            }
        },
        watch: {
            input(v) {
                if (v.indexOf("@") === -1) {
                    this.form.mobile = v
                    this.form.email = ""
                    this.form.type = 'mobile'
                } else {
                    this.form.mobile = ""
                    this.form.email = v
                    this.form.type = 'email'
                }
            }

        },
        destroyed() {
            if (codeTimer) clearInterval(codeTimer)
        },
        methods: {
            async sendCode() {
                try {
                    this.sendCodeLoading = true
                    if (!this.input) {
                        this.$message.warning("请输入手机号码/邮箱")
                        return
                    }
                    if (this.form.type === 'mobile') {
                        await axiosZX.request({
                            url: "api/sms/send",
                            method: "POST",
                            data: {
                                mobile: this.form.mobile,
                                event: "resetpwd"
                            }
                        })
                    }
                    if (this.form.type === 'email') {
                        await axiosZX.request({
                            url: "api/ems/send",
                            method: "POST",
                            data: {
                                email: this.form.email,
                                event: "resetpwd"
                            }
                        })
                    }


                    this.sendCodeTime = 60
                    this.sendCodeBtnTxt = `重试（${this.sendCodeTime}秒）`
                    codeTimer = setInterval(() => {
                        this.sendCodeTime -= 1
                        this.sendCodeBtnTxt = `重试（${this.sendCodeTime}秒）`
                        if (this.sendCodeTime === 0) {
                            clearInterval(codeTimer)
                            this.sendCodeBtnTxt = '发送验证码'
                        }
                    }, 1000);
                } catch (err) {

                } finally {
                    this.sendCodeLoading = false
                }







            },
            async step() {
                let { msg } = await axiosZX.request({
                    url: "api/user/resetpwd",
                    method: "POST",
                    data: this.form
                })
                this.$message.success(msg)
                this.back()
            },
            back() {
                this.$router.back()
            }
        }
    }
</script>

<style scoped lang="less">
    .page {
        height: calc(100vh - 128px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .block {
        width: 400px;
    }

    .title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #283235;
        margin-bottom: 48px;
    }

    .inputMsg {
        margin-top: 8px
    }


    .step {
        width: 100%;
        margin-top: 40px;
        background-color: rgba(30, 205, 164, 1);
        color: #FFFFFF;
    }

    .back {
        width: 100%;
        margin-top: 20px;
        color: rgba(30, 205, 164, 1);
        font-size: 16px;
        font-weight: 400;
        color: #1ECDA4;
    }
</style>